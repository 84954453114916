$(document).ready(function () {
	var galleryPS = $(".fancybox").jqPhotoSwipe({
		shareButtons: [
			{id:'details', label:'Artwork details', url:'{{detail_link}}'},
			{id:'facebook', label:'Share on Facebook', url:'https://www.facebook.com/sharer/sharer.php?u={{url}}'},
			{id:'twitter', label:'Tweet', url:'https://twitter.com/intent/tweet?text={{text}}&url={{url}}'},
			{id:'pinterest', label:'Pin it', url:'http://www.pinterest.com/pin/create/button/?url={{url}}&media={{image_url}}&description={{text}}'}
		],
		parseShareButtonOut: function(shareButtonData, shareButtonOut){
            if(shareButtonOut.includes('{{detail_link}}'))
                shareButtonOut = shareButtonOut.replace('_blank', '_self').replace('class="', 'onclick="event.stopPropagation();" class="hover-inverted ');
			return shareButtonOut.replace('{{detail_link}}', galleryPS.galleries[0].obj.currItem.link);
		},
        addCaptionHTMLFn: function(item, captionEl, isFake) {
            // item      - slide object
            // captionEl - caption DOM element
            // isFake    - true when content is added to fake caption container
            //             (used to get size of next or previous caption)
            if(!item.title) {
                captionEl.children[0].innerHTML = '';
                return false;
            }
            captionEl.children[0].innerHTML = '<a href="' + item.link + '">' + item.title + '</a>';
            return true;
        },
        clickToCloseNonZoomable: false
	});

    $('.rating').barrating({
        theme: "arsoluta"
    });
    $('.rating.readonly').barrating('readonly', true);

    $('.select2').select2();

    $('.event-content-select').select2({
        ajax: {
            type: "POST",
            // url: '/api/artworks',
            dataType: "json",
            cache: true,
            data: function (data) {
                return {
                    search: data.term,
                    ignore: $(this).val()
                };
            },
            processResults: function (data) {
                return {
                    results: data.results
                };
            }
        },
        minimumInputLength: 2,
        templateResult: function(content){
            if (content.loading) {
                return content.title;
            }

            if(content.type == 'artwork')
                var $container = $(
                    "<div class='p-2' style='display:flex;flex-direction:row;'>" +
                        "<div class='pr-2'><img src='" + content.mediaUrl + "' style='height:100px' /></div>" +
                        "<div class=''>" +
                            "<h5>" + content.title.replace(content.search, "<mark>"+content.search+"</mark>") + "</h5>" +
                            "<h6>" + content.artist + "</h6>" +
                        "</div>" +
                    "</div>"
                );
            else if(content.type == 'artist')
                var $container = $(
                    "<div class='p-2' style='display:flex;flex-direction:row;'>" +
                        "<div class='pr-2'><img src='" + content.mediaUrl + "' style='height:100px; border-radius: 50%' /></div>" +
                        "<div class=''>" +
                            "<h5>" + content.nickname.replace(content.search, "<mark>"+content.search+"</mark>") + "</h5>" +
                        "</div>" +
                    "</div>"
                );

            return $container;
        },
        templateSelection: function(content){
            return content.title;
        }
    });
    $('.event-content-select').on("select2:select", function(e) {
        var contentType = $(this).data("content-type");

        var content = $(this).select2("data").find(x => x.id === e.params.data.id.toString());
        var removeBtn = $('<div class="col-2 col-sm-1 text-center" data-id="'+content.id+'"><button class="btn btn-danger">⊖</button></div>');
        var select = "#event-"+contentType+"-select";

        removeBtn.click(function(){
            var values = $(select).val();
            if (values) {
                var i = values.indexOf($(this).data("id").toString());
                if (i >= 0) {
                    values.splice(i, 1);
                    $(select).val(values).change();
                }
            }
            $(this).closest('.event-'+contentType).remove();
        });

        if(contentType == "artworks")
            var element = $(
                "<div class='p-2 col-12 event-"+contentType+"' style='display:flex;flex-direction:row;align-items:center'>" +
                    "<div class='pr-2'><img src='" + content.mediaUrl + "' style='height:100px' /></div>" +
                    "<div class=''>" +
                        "<h5>" + content.title + "</h5>" +
                        "<h6>" + content.artist + "</h6>" +
                    "</div>" +
                "</div>"
            );
        else if(contentType == "artists")
            var element = $(
                "<div class='p-2 col-12 event-"+contentType+"' style='display:flex;flex-direction:row;align-items:center'>" +
                    "<div class='pr-2'><img src='" + content.mediaUrl + "' style='height:100px; border-radius: 50%' /></div>" +
                    "<div class=''>" +
                        "<h5>" + content.nickname + "</h5>" +
                    "</div>" +
                "</div>"
            );
        element.prepend(removeBtn);
        $("section."+contentType).append(element);
    });
    $('.event-content-select').on("select2:unselecting", function(e) {
        e.preventDefault();
    });

});


$('#notificationDropdown').on('click', function(){
    if($(this).find('.badge').length == 0) return;
    $(this).find('.badge').remove();
    $.get('/notifications/viewed');
});

$(document).on('click', '.pswp__img', function(e){
    var destination = $(this).closest('.pswp').find('.pswp__caption__center a').last().attr('href');
    if(destination) location.href = destination;
});