$('.input-images .add-image').on('click', function(){
    $(this).closest('.input-images').find('.choose-images').trigger('click');
    return false;
});
$('.input-images .choose-images').on('change', function(){
    let previewPane = $(this).closest('.input-images').find('.images-preview');
    previewPane.empty();
    previewPane.siblings('.text-danger').addClass('d-none');
    if(this.files.length < $(this).data('min')){
        this.value = '';
        previewPane.siblings('.min-files').removeClass('d-none');
        return;
    }
    if(this.files.length > $(this).data('max')){
        this.value = '';
        previewPane.siblings('.max-files').removeClass('d-none');
        return;
    }
    for(var i in this.files){
        let file = this.files[i];
        
        if(file && typeof file === 'object')
            previewPane.append('<img src="'+ URL.createObjectURL(file) +'" class="img-preview px-2" alt="Image preview"/>');
    }
});