$(document).on("click", "[modal-confirm]", function(e){

    e.preventDefault();

    var trigger = $(this);

    var options = {
        title: trigger.attr('title') !== undefined ? trigger.attr('title') : '',
        selector: trigger.attr('modal-confirm') !== undefined ? trigger.attr('modal-confirm') : '',
        dismiss: trigger.data('dismiss') !== undefined ? trigger.data('dismiss') : '',
        confirm: trigger.data('confirm') !== undefined ? trigger.data('confirm') : '',
        content: trigger.data('content') !== undefined ? trigger.data('content') : '',
    };

    var buttons = '';
    if(options.dismiss)
        buttons += '<button type="button" class="btn btn-secondary" data-dismiss="modal">'+ options.dismiss +'</button>';
    buttons += '<button type="button" class="btn btn-danger" onclick="$(\''+options.selector+'\').submit(); return false;">'+ (options.confirm ? options.confirm : trigger.html()) +'</button>';

    var modal = `
  <div class="modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-sans">`+ (options.title ? options.title : '!!!') +`</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>`+ options.content +`</p>
        </div>
        <div class="modal-footer">
          <input type="hidden" name="_method" value="`+ (options.method ? options.method : 'get') +`">`+
          buttons
        +`</div>
      </div>
    </div>
  </div>`;

    $(modal).modal({show: true});

    return false;
    
});