$(document).on("change", "[cascading-radio-parent] input", function(e){
    
    var checked = $(this).is(':checked');
    cascadingRadioHideFor(this.name, this.value, checked);
    if(checked) cascadingRadioShowFor(this.name, this.value);

});

// clear child selection
function cascadingRadioHideFor(name, value, checked){
    value = checked ? null : value;
    var selector = "[cascading-radio-parent='"+ name +"']";
    if(value !== null){
        $(selector +"[cascading-radio-value-any]").addClass("d-none");
        selector += "[cascading-radio-value='"+ value +"']";
    }
    $(selector).addClass("d-none");
    $(selector +" input:checked").prop("checked", false).trigger("change");
    $(selector +" .btn.active").removeClass("active");
}

// set new child
function cascadingRadioShowFor(name, value){
    $("[cascading-radio-parent='"+ name +"'][cascading-radio-value='"+ value +"']").removeClass("d-none");
    $("[cascading-radio-parent='"+ name +"'][cascading-radio-value-any]").removeClass("d-none");
}

$("[cascading-radio-parent] input:checked").each(function(index){
    cascadingRadioShowFor(this.name, this.value);
});