require('./bootstrap');

require('alpinejs');

require('./jquery.barrating.min.js');
require('./jqPhotoSwipe.min');
require('bootstrap-select');

require('./custom.js');
require('./inputImages.js');

require('./comments.js');

require('./cascadingRadio.js');
require('./modalConfirm.js');