$(document).on("submit", ".insert_comment", function(e){

    e.preventDefault();
    var form = $(this);

    var posting = $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: form.serialize()
    });

    form.find("input[name='comment_body']").val("");

    /* Alerts the results */
    posting.done(function(data) {
        var res = JSON.parse(data);
        let newRow = $("#example_row").clone();
        newRow.removeAttr("id");
        newRow.removeClass("d-none").addClass("d-flex");
        newRow.find(".comment_body").html(res.body);
        if(res.parent_id)
            newRow.find("[comment-id]").remove();
        else
            newRow.find("[comment-id]").attr("comment-id", res.id);
        form.siblings(".comments_container").prepend(newRow);
    });
    posting.fail(function() {
        alert('errore');
    });

    return false;
});

$(document).on("click", "[comment-id]", function(e){
    e.preventDefault();

    var link = $(this);

    var getComments = $.get( "/comment/" + $(this).attr("comment-id") + "/children" );
    getComments.done(function(html){
        link.parent().after(html);
        link.remove();
    });

    return false;
});

$(document).on("click", "[comment-delete-id]", function(e){
    e.preventDefault();
    var form = $(".insert_comment");
    var id = $(this).attr("comment-delete-id");

    if (confirm($(this).attr('delete-confirm'))) {
        var posting = $.ajax({
            type: "POST",
            url: '/api/comments/' + $(this).attr("comment-delete-id") + '/delete',
            data: form.serialize()
        });
        posting.done(function(data) {
            $("[comment-delete-id=" + id  + "]").parents('.comment-wrapper').remove();
        });
        posting.fail(function() {
            alert('errore');
        });
    }

    return false;
});
